
.event-view-dialog{
    min-width: 267px;
    max-width: 320px;
    background-color: white;
    max-height: 300px !important;
    overflow-x: hidden !important;
}

.no-court-event-view{
    min-height: 318px;
}

.event-view-dialog-header{

border-bottom: 0.89px solid #0000001A;

}

.event-view-dialog-header>h1{
font-weight: 500;
color: #333333;
font-size: 15px;
line-height: 17.58px;
letter-spacing: 0.23px;
font-family: 'Roboto';
}



.header-icon{
    font-size: 10px;
    cursor: pointer;
}



.event-view-schedule{
    display: flex;
    flex-direction: row;
    
}

.event-view-schedule>i{
font-size: 14.05px;
align-self:flex-start;
}


.event-view-schedule>div>p{
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 500;
    line-height: 21.29px;
    margin-top: -3px;
    color: #000000;
}

.event-view-schedule>div>span{
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21.29px;
    color: #333333;
}



.court-info>div>span{
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 21.29px;
    color: #333333;
}

.error-message-view-event{
    color: #E80000;
    font-weight: 500;
    font-size: 8.5px;
    line-height: 8px;
    text-align: start;
}

.event-list-footer{
    border-top: 1px solid #0000001A;
}