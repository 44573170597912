.week-calendar {
    position: relative;
    overflow: auto;
    height: calc(100vh - 25vh);
    overflow-x: hidden;

    .header-row {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;

        &::before {
            content: '';
            width: 6rem;
            height: 5.5rem;
            position: absolute;
            background: #fff;
            left: -5%;
        }

        .calendar-header {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            gap: 10px;
            border-left-width: 1px;
            border-top-width: 0px;
            border-bottom-width: 1px;
            border-right-width: 1px;

            border-color: #E9E9E9;
            border-style: solid;

            p {
                font-family: 'Poppins';
                font-weight: 500;

                &.p-date {
                    color: #000000;
                    font-size: 18px;
                }

                &.p-date-active {
                    color: var(--primary-color);
                    font-size: 18px;
                }

                &.p-day {
                    color: Grey;
                    font-size: 15px;
                    letter-spacing: 1%;
                }
            }

            &::before {
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 55%;
                left: -3px;
                top: 0;
            }

            &::after {
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 55%;
                right: -1px;
                top: 0;
            }
        }
    }

    .calendar-row {
        position: relative;

        .calendar-row-cell-time {
            font-weight: 500;
            font-size: 10px;
            letter-spacing: 0.5px;
            position: absolute;
            bottom: -6px;
            left: -40px;
        }

        .calendar-row-cell {
            &.calendar-grid{
                border-bottom: 1px solid #E9E9E9;
                height: 70px;
            }
            &.calendar-col{
                min-height: 100dvh;
                flex-direction: column;
                gap: 5px;
                &:first-child{
                    border-left: 1px solid #E9E9E9;
                }
                
            }
            display: flex;
            position: relative;
            overflow: hidden;

            &:nth-child(2) {
                border-left: 1px solid #E9E9E9;
                border-right: 1px solid #E9E9E9;
            }

            &:not(:first-child) {
                border-right: 1px solid #E9E9E9;
            }
        }

    }

    .event-card {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        flex-direction: column;
        max-height: 60px;
        min-height: 60px;
        position: absolute;
        width: 90%;

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.schedule-play-bg {
            border: 2px solid #EBA1FF;
            border-radius: 6px;
            background-color: #F9E3FF;

            .court-count-wraper {
                background: #F5D0FF;
                border: 2px solid #EBA1FF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.danger-bg {
            border: 2px solid #ff0000;
            border-radius: 6px;
            background-color: #ffabab;

            .court-count-wraper {
                background: #ff8c8c;
                border: 2px solid #FF6D6D;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.view-more-events {
            border: 2px solid #D0D0D0;
            border-radius: 6px;
            background-color: #FAFAFA;

            .court-count-wraper {
                background: #FAFAFA;
                border: 1px solid #D0D0D0;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #8A8A8A;
                }
            }
        }

        &:hover {
            z-index: 2;
            transform: scale(1.05);
        }

        .title {
            color: #000000;
            font-weight: 600;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 112px;
            min-width: 112px;
        }

        .event-info {
            p {
                color: #000000;
                font-size: 9px;
                font-weight: 400;
            }
        }

        &.lf-1 {
            left: 27px;
            width: 76%;
        }

        &.lf-2 {
            left: 60px;
            width: 64%;
        }

        &.more-events {
            right: 0;
            // width: 45px;
            align-items: center;
            justify-content: center;
        }
    }

    .token-event-card {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width: 100%;
        max-height: 70px;
        min-height: 70px;
        
        // width: 100%;

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        .title {
            color: #000000;
            font-weight: 600;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 90%;

        }

        .event-info {
            p {
                color: #000000;
                font-size: 10px;
                font-weight: 500;
            }
            .token-count{
                font-weight: 600;
            }
        }

        &:last-child{
            margin-bottom: 100px;
        }
    }

    .event-card:nth-child(3) {
        // left: 125px;
        right: 0;
        width: 45px;
        align-items: center;
        justify-content: center;
    }

    .event-card-mob:nth-child(3) {
        align-items: center;
        justify-content: center;
        bottom: 1px;
        border-radius: 4px !important;
        min-height: 15px !important;
        padding-top: 5px !important;

        .title-more {
            font-size: 12px;
        }
    }

    .event-card-mob {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        flex-direction: column;
        max-height: 12px;
        min-height: 12px;
        width: 100%;
        position: relative;
        margin-bottom: 3px;
        padding-top: 0px !important;

        .title {
            font-size: 8px;
            word-wrap: break-word;
        }

        .event-info-container {
            display: none !important;
        }

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 8px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.danger-bg {
            border: 2px solid #ff0000;
            border-radius: 6px;
            background-color: #ffabab;

            .court-count-wraper {
                background: #ff8c8c;
                border: 2px solid #FF6D6D;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 8px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.schedule-play-bg {
            border: 2px solid #EBA1FF;
            border-radius: 6px;
            background-color: #F9E3FF;

            .court-count-wraper {
                background: #F5D0FF;
                border: 2px solid #EBA1FF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }
    }
}

.day-calendar {
    position: relative;
    overflow: auto;
    height: calc(100vh - 22vh);
    overflow-x: hidden;

    .header-row {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 100;

        &::before {
            content: '';
            width: 6rem;
            height: 5rem;
            position: absolute;
            background: #fff;
            left: -8%;
        }

        .calendar-header {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            gap: 10px;
            border-left-width: 1px;
            border-top-width: 0px;
            border-bottom-width: 1px;
            border-right-width: 1px;

            border-color: #E9E9E9;
            border-style: solid;

            p {
                font-family: 'Poppins';
                font-weight: 500;

                &.p-date {
                    color: #000000;
                    font-size: 18px;
                }

                &.p-date-active {
                    color: var(--primary-color);
                    font-size: 18px;
                }

                &.p-day {
                    color: Grey;
                    font-size: 15px;
                    letter-spacing: 1%;
                }
            }

            &.calendar-col::before{
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 100%;
                left: -3px;
                top: 0;
            }

            &.calendar-grid::before{
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 55%;
                left: -3px;
                top: 0;
            }

            &.calendar-col::after{
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 100%;
                right: -1px;
                top: 0;
            }

            &.calendar-grid::after{
                content: '';
                position: absolute;
                width: 5px;
                background: #fff;
                height: 55%;
                right: -1px;
                top: 0;
            }
            
        }
    }

    .calendar-row {
        position: relative;

        .calendar-row-cell-time {
            font-weight: 500;
            font-size: 10px;
            letter-spacing: 0.5px;
            position: absolute;
            bottom: -6px;
            left: -40px;
        }

        .calendar-row-cell {
            &.calendar-grid{
                border-bottom: 1px solid #E9E9E9;
                flex-direction: row;
                height: 70px;
            }
            &.calendar-col{
                flex-direction: column;
                gap: 5px;
                align-items: center;
            }
            display: flex;
            position: relative;
            overflow: hidden;

            &:nth-child(2) {
                border-left: 1px solid #E9E9E9;
                border-right: 1px solid #E9E9E9;
            }

            &:not(:first-child) {
                border-right: 1px solid #E9E9E9;
            }
        }

    }

    .event-card.week-view-slot {
        max-width: 150px;
    }

    .event-card {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        flex-direction: column;
        max-height: 60px;
        min-height: 60px;
        position: absolute;
        max-width: 150px;
        min-width: 150px;
        
        // width: 100%;

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.schedule-play-bg {
            border: 2px solid #EBA1FF;
            border-radius: 6px;
            background-color: #F9E3FF;

            .court-count-wraper {
                background: #F5D0FF;
                border: 2px solid #EBA1FF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.danger-bg {
            border: 2px solid #ff0000;
            border-radius: 6px;
            background-color: #ffabab;

            .court-count-wraper {
                background: #ff8c8c;
                border: 2px solid #FF6D6D;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }


        &.view-more-events {
            border: 2px solid #D0D0D0;
            border-radius: 6px;
            background-color: #8A8A8A;

            .court-count-wraper {
                background: #8A8A8A;
                border: 1px solid #D0D0D0;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #8A8A8A;
                }
            }
        }

        &:hover {
            z-index: 2;
            transform: scale(1.01);
        }

        .title {
            color: #000000;
            font-weight: 600;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 90%;

        }

        .event-info {
            p {
                color: #000000;
                font-size: 9px;
                font-weight: 400;
            }
        }

        &.lf-1 {
            left: 30px;
        }

        &.lf-2 {
            left: 60px;
        }

        &.lf-3 {
            // left: px;
            right: 0;
            width: 45px;
            align-items: center;
            justify-content: center;
        }

        &.more-events {
            right: 0;
            // width: 45px;
            align-items: center;
            justify-content: center;
        }
    }

    

    .token-event-card {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        max-width: 50%;
        min-width: 50%;
        padding: 0.5rem !important;
        
        // width: 100%;

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        .title {
            color: #000000;
            font-weight: 600;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 90%;

        }

        .event-info {
            display: flex;
            gap: 6px;
            p {
                color: #000000;
                font-size: 10px;
                font-weight: 500;
            }
            .token-count{
                font-weight: 600;
            }
        }

        &.lf-1 {
            left: 30px;
        }

        &.lf-2 {
            left: 60px;
        }

        &.lf-3 {
            // left: px;
            right: 0;
            width: 45px;
            align-items: center;
            justify-content: center;
        }

        &.more-events {
            right: 0;
            // width: 45px;
            align-items: center;
            justify-content: center;
        }
    }


    // .event-card:nth-child(3) {
    // left: 125px;
    // right: 0;
    // width: 45px;
    // align-items: center;
    // justify-content: center;
    // }
}

.month-calendar {
    position: relative;
    overflow: auto;
    height: 75vh;
    overflow-x: hidden;

    .calendar-row {
        position: relative;

        .calendar-row-cell {
            border-bottom: 1px solid #E9E9E9;
            position: relative;
            min-height: 135px;

            &:nth-child(2) {
                border-left: 1px solid #E9E9E9;
                border-right: 1px solid #E9E9E9;
            }

            &:not(:first-child) {
                border-right: 1px solid #E9E9E9;
            }

            &:last-child {
                border-right: 0px;
            }

            .calendar-row-cell-header {

                p {
                    &.weekday {
                        color: #4F4F4F;
                        font-weight: 500;
                        letter-spacing: 1px;
                        font-size: 14px;
                    }

                    &.weekday-active {
                        color: var(--primary-color);
                    }

                    &.weekdate {
                        color: #000000;
                        font-weight: 500;
                        letter-spacing: 1px;
                        font-size: 13px;
                    }

                    &.weekdate-active {
                        color: var(--primary-color);
                    }
                }

            }

            .calendar-row-cell-body {
                position: relative;
            }
        }
    }

    .event-card {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        flex-direction: column;
        min-height: 70px;
        position: absolute;

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.danger-bg {
            border: 2px solid #FF6D6D;
            border-radius: 6px;
            background-color: #FFADAD;

            .court-count-wraper {
                background: #EB9797;
                border: 2px solid #FF6D6D;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #fff;
                }
            }
        }

        &:hover {
            z-index: 2;
            transform: scale(1.05);
        }

        .title {
            color: #000000;
            font-weight: 600;
            font-size: 9px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 112px;
            min-width: 112px;
        }

        .title-more {
            color: #333333;
            font-weight: 600;
            font-size: 14px;
        }

        .event-info {
            p {
                color: #000000;
                font-size: 8px;
                font-weight: 500;
            }
        }

        &.lf-1 {
            left: 30px;
        }

        &.lf-2 {
            left: 60px;
        }

        &.lf-3 {
            left: 120px;
        }
    }

    .event-card:not(:first-child) {
        top: 0;
        flex-direction: column;
    }

    .event-card:nth-child(2) {
        left: 40px;
    }

    .event-card:nth-child(3) {
        right: 0;
        width: 40px;
        align-items: center;
        justify-content: center;
    }

    .event-card-mob {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        flex-direction: column;
        max-height: 12px;
        min-height: 12px;
        width: 32px;
        position: relative;
        margin-bottom: 3px;
        padding-top: 0px !important;

        .title {
            font-size: 8px;
            word-wrap: break-word;
        }

        .event-info-container {
            display: none !important;
        }

        &.primary-bg {
            border: 2px solid #ADDCFF;
            border-radius: 6px;
            background-color: #E3F3FF;

            .court-count-wraper {
                background: #C5E6FF;
                border: 1px solid #ADDCFF;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 8px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }

        &.danger-bg {
            border: 2px solid #FF9F9F;
            border-radius: 6px;
            background-color: #FFE3E3;

            .court-count-wraper {
                background: #FFE3E3;
                border: 1px solid #FF9F9F;
                border-radius: 100px;
                width: 25px;
                height: 25px;
                text-align: center;
                align-content: center;

                p {
                    font-size: 8px;
                    font-weight: 500;
                    color: #333333;
                }
            }
        }
    }

    .tokens-sum{
        position: absolute;
        right: 3px;
        bottom: 0;
        margin-bottom: 0px !important;
    }
}

.calendar-day-icons-container {
    position: fixed;
    left: 520px;
    top: 80px;
}

.calendar-day-icons-container i {
    padding: 10px;
}

.calendar-day-icons-container i:hover {
    border-radius: 100%;
    background-color: #ECECEC;
    padding: 10px;
}

.month-event-description>p {
    font-size: 9px;
    font-weight: 400;
    font-family: 'Poppins';
    line-height: 20px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70%;
}

.month-event-description>span {
    font-size: 9px;
    line-height: 13.5px;
    font-weight: 600;
    font-family: 'Poppins';
    align-self: center;
}

.more-event-div {
    max-width: 41px;
    min-height: 15px;
    border-radius: 3px;
    background-color: #ADDCFF;

    span {
        font-size: 9px;
        font-weight: 500;
        color: #000000;
        line-height: 13.5px;
    }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1420px) {

    /* Your CSS styles go here */
    .week-calendar {
        .event-card {
            width: 90%;

            .title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 75%;
                // min-width: 75%;
            }

            &.more-events {
                right: 0;
                // width: 45px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
    .week-calendar {
        .event-card {
            width: 93%;
        }
    }

    .day-calendar{
        height: 85vh;
    }

    .month-calendar {
        height: 85vh;

        .calendar-row {
            .calendar-row-cell {
                min-height: 160px;
            }
        }
    }
}

@media only screen and (min-device-width : 712px) and (max-device-width : 1024px) {
    .week-calendar {
        .event-card {
            max-width: 90%;

            &.lf-1 {
                max-width: 70%;
            }

            .title {
                font-size: 7px;
            }

            .event-info {
                p {
                    font-size: 7px;
                }
            }
        }
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
    .month-calendar,.day-calendar{
        height: 72dvh;
    }
    
    .week-calendar {
        padding-left: 3rem !important;
        height: 72dvh;

        .calendar-header {
            width: 14.28%;

            .p-date,
            .p-date-active {
                font-size: 14px !important;
            }

            .p-day {
                font-size: 11px !important;
            }
        }

        .header-row::before {
            width: 3rem;
            left: -15%;
            height: 5.5rem;
        }

        .calendar-row-cell {
            height: 50px !important;
            padding: 4px 3px 3px 4px;
        }
    }

    //    .month-calendar{
    //     .event-card-mob {
    //         display: flex;
    //         justify-content: space-around;
    //         cursor: pointer;
    //         flex-direction: column;
    //         max-height: 60px;
    //         min-height: 60px;
    //         position: absolute;

    //         &.primary-bg {
    //             border: 2px solid #ADDCFF;
    //             border-radius: 6px;
    //             background-color: #E3F3FF;

    //             .court-count-wraper {
    //                 background: #C5E6FF;
    //                 border: 1px solid #ADDCFF;
    //                 border-radius: 100px;
    //                 width: 25px;
    //                 height: 25px;
    //                 text-align: center;
    //                 align-content: center;
    //                 p {
    //                     font-size: 11px;
    //                     font-weight: 500;
    //                     color: #333333;
    //                 }
    //             }
    //         }


    //         .title {
    //             color: #000000;
    //             font-weight: 600;
    //             font-size: 10px;
    //             overflow: hidden;
    //             text-overflow: ellipsis;
    //             white-space: nowrap;
    //             max-width: 112px;
    //             min-width: 112px;
    //         }

    //         .event-info {
    //             p {
    //                 color: #000000;
    //                 font-size: 9px;
    //                 font-weight: 400;
    //             }
    //         }

    //         &.lf-1 {
    //              left: 27px;
    //              width: 76%;
    //         }

    //         &.lf-2 {
    //             left: 60px;
    //             width: 64%;
    //         }

    //         &.more-events{
    //             right: 0;
    //             // width: 45px;
    //             align-items: center;
    //             justify-content: center;
    //         }
    //     }
    //    }
}